import { useState } from "react";
import { firebase } from "../firebase";
import { Button, Typography } from "@material-tailwind/react";
import FormWrapper from "components/Form/FormWrapper";
import { useForm } from "react-hook-form";
import { TypeOf, boolean, object, string, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import FormInput from "components/Form/FormInput";
import { getDBUserByUID } from "hooks/useAuth";
import { User } from "types";
import { toast } from "react-toastify";
import { validateOTP } from "api/auth";
import { createSearchParams, useNavigate } from "react-router-dom";
import { routes } from "routes";
import useLocalStorage from "hooks/useLocalStorage";
import { v4 as uuidv4 } from "uuid";

export const loginFormSchema = object({
  email: string()
    .min(1, "Email is required")
    .email("Invalid email"),
  password: string().min(1, "Password is required"),
  isMFAEnabled: boolean().optional(),
  trustDevide: boolean().optional(),
  otp: string().optional()
}).superRefine((values, context) => {
  if (values.isMFAEnabled && !(values.otp?.length === 6)) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      message: "OTP is required",
      path: ["otp"]
    });
  }
});

export type LoginForm = TypeOf<typeof loginFormSchema>;

const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [browserId] = useLocalStorage("browserId", uuidv4());

  const formMethods = useForm<LoginForm>({
    defaultValues: {
      email: "",
      password: "",
      isMFAEnabled: false,
      otp: "",
      trustDevide: false
    },
    resolver: zodResolver(loginFormSchema)
  });

  const handleLogin = async (data: LoginForm) => {
    const handleError = async () => {
      await firebase.auth().signOut();
      navigate({
        pathname: routes.auth.login.path,
        search: createSearchParams({}).toString()
      });
    };
    const handleSuccess = async () => {
      navigate({
        search: createSearchParams({}).toString()
      });
      // sign out / sign in to update the auth state
      await firebase.auth().signOut();
      await firebase.auth().signInWithEmailAndPassword(data.email, data.password);
    };

    try {
      setIsLoading(true);
      setError("");
      navigate({
        pathname: routes.auth.login.path,
        search: createSearchParams({ suspend: "true" }).toString()
      });
      const firebaseUser = await firebase.auth().signInWithEmailAndPassword(data.email, data.password);
      const tokenResult = await firebaseUser.user?.getIdTokenResult();
      const claims = tokenResult?.claims || {};
      const hasAccessToLogin = claims.role === "super-admin";
      if (!hasAccessToLogin) {
        setError("You don't have access to this application");
        return await handleError();
      }
      const dbUser = await getDBUserByUID(firebaseUser.user?.uid);

      if (!dbUser.exists) {
        setError("User not found");
        return await handleError();
      }

      const userData: User = dbUser.data() as User;
      const dbUserTrustedDevices = userData.csSettings?.trustedDevices || [];
      const isMFAEnabled =
        !!(userData.csSettings?.isMFAEnabled && userData.csSettings?.isMFAVerified) &&
        !dbUserTrustedDevices.includes(browserId);

      if (isMFAEnabled && !data.otp) {
        formMethods.setValue("isMFAEnabled", true);
        toast.info("Your account has 2FA enabled, please enter your OTP");
        return await handleError();
      }

      if (isMFAEnabled && data.otp) {
        try {
          const validationResponse = await validateOTP(data.otp);
          if (!validationResponse.otpValid) {
            throw new Error("Invalid OTP");
          }
          if (data.trustDevide) {
            await dbUser.ref.set(
              {
                csSettings: {
                  trustedDevices: firebase.firestore.FieldValue.arrayUnion(browserId)
                }
              },
              { merge: true }
            );
          }
          return await handleSuccess();
        } catch (error) {
          formMethods.setError("otp", {
            type: "manual",
            message: (error as any)?.message || "Invalid OTP"
          });
          return await handleError();
        }
      }
      return await handleSuccess();
    } catch (error) {
      console.log(error);
      if ((error as any).code === "auth/wrong-password") {
        setError("Invalid email or password");
      } else {
        setError("Something went wrong");
      }
      return await handleError();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="m-8 flex gap-4">
      <div className="mt-24 w-full lg:w-3/5">
        <div className="text-center">
          <Typography variant="h2" className="mb-4 font-bold">
            Sign In
          </Typography>
          <Typography variant="paragraph" color="blue-gray" className="text-lg font-normal">
            Enter your email and password to Sign In.
          </Typography>
        </div>
        <FormWrapper
          formMethods={formMethods}
          error={error}
          onSubmit={formMethods.handleSubmit(handleLogin)}
          className="mx-auto mb-2 mt-8 w-80 max-w-screen-lg lg:w-1/2 "
        >
          <div className="mb-1 flex flex-col gap-6">
            <FormInput<LoginForm> name="email" type="email" size="lg" placeholder="name@mail.com" />
            <FormInput<LoginForm> name="password" type="password" size="lg" placeholder="********" />
            {formMethods.watch("isMFAEnabled") && (
              <>
                <FormInput<LoginForm> name="otp" label="OTP" size="lg" type="text" placeholder="123456" />
                <FormInput<LoginForm> name="trustDevide" type="checkbox" label="Trust this device" />
              </>
            )}
          </div>
          <Button className="mt-6 text-center" type="submit" fullWidth disabled={isLoading}>
            {!isLoading ? "Sign In" : "Loading..."}
          </Button>
        </FormWrapper>
      </div>
      <div className="hidden h-full w-2/5 lg:block">
        <img src="/img/pattern.png" className="h-full w-full rounded-3xl object-cover" />
      </div>
    </section>
  );
};

export default LoginPage;
