import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Dialog, DialogBody, DialogHeader, Typography } from "@material-tailwind/react";
import { validateOTP } from "api/auth";
import FormInput from "components/Form/FormInput";
import FormWrapper from "components/Form/FormWrapper";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { TypeOf, object, string } from "zod";

export const mfaModalFormSchema = object({
  otp: string().min(1, "Authentication code is required")
});

export type MFAModalForm = TypeOf<typeof mfaModalFormSchema>;

const MFAModal = ({
  base32,
  qrCode,
  isOpen,
  setIsOpen,
  onlyView
}: {
  base32: string;
  qrCode: string;
  isOpen: boolean;
  setIsOpen: (a: { open: boolean; shouldReset?: boolean }) => void;
  onlyView?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const formMethods = useForm<MFAModalForm>({
    defaultValues: {
      otp: ""
    },
    resolver: zodResolver(mfaModalFormSchema)
  });

  useEffect(() => {
    formMethods.reset({
      otp: ""
    });
  }, [isOpen]);

  const onSubmit = async (data: MFAModalForm) => {
    try {
      setIsLoading(true);
      const response = await validateOTP(data.otp);
      if (response.otpValid) {
        setIsOpen({ open: false, shouldReset: false });
        toast.success("2FA has been enabled successfully");
        return;
      }
      throw new Error("Failed to enable 2FA");
    } catch (error) {
      formMethods.setError("otp", {
        type: "manual",
        message: (error as any)?.message || "Failed to enable 2FA"
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      handler={open => {
        if (onlyView) {
          setIsOpen({
            open,
            shouldReset: false
          });
        } else {
          setIsOpen({
            open,
            shouldReset: true
          });
        }
      }}
    >
      <DialogHeader>
        <Typography variant="h4">Two-Factor Authentication (2FA)</Typography>
      </DialogHeader>
      <DialogBody className="text-blue-gray-800">
        <Typography variant="h5">Configuring Google Authenticator or Authy</Typography>
        <ul>
          <li>Install Google Authenticator (IOS - Android) or Authy (IOS - Android).</li>
          <li>In the authenticator app, select "+" icon.</li>
          <li>Select "Scan a barcode (or QR code)" and use the phone's camera to scan this barcode.</li>
        </ul>
        <div>
          <Typography variant="h5">Scan QR Code</Typography>
          <div className="flex justify-center">
            <img className="block w-64 h-64 object-contain" src={qrCode} alt="qrcode url" />
          </div>
        </div>
        <div>
          <Typography variant="h5">Or Enter Code Into Your App</Typography>
          <p className="text-sm">SecretKey: {base32} (Base32 encoded)</p>
        </div>
        {!onlyView ? (
          <div>
            <Typography variant="h5">Verify Code</Typography>
            <p className="text-sm">For changing the setting, please verify the authentication code:</p>
            <FormWrapper formMethods={formMethods} onSubmit={formMethods.handleSubmit(onSubmit)} className="mt-2">
              <FormInput<MFAModalForm> containerClassName="w-56" name="otp" label="Enter OTP" type="text" />
              <div className="mt-2 flex gap-2 justify-end w-full">
                <Button type="submit" disabled={isLoading} loading={isLoading} size="sm">
                  Verify
                </Button>
                <Button
                  variant="outlined"
                  color="red"
                  onClick={() => {
                    setIsOpen({ open: false, shouldReset: true });
                  }}
                  disabled={isLoading}
                  size="sm"
                >
                  Cancel
                </Button>
              </div>
            </FormWrapper>
          </div>
        ) : (
          <div className="w-full justify-end flex">
            <Button
              variant="outlined"
              onClick={() => {
                setIsOpen({ open: false, shouldReset: false });
              }}
              size="sm"
            >
              Close
            </Button>
          </div>
        )}
      </DialogBody>
    </Dialog>
  );
};

export default MFAModal;
