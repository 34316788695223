import { Account, Business } from "types";

export const getBusinessAddress = (business?: Business) => {
  const address = business?.address;
  if (!address) return "";
  const { address1, address2, city, country, postal, state } = address;
  return [address1, address2, city, state, postal, country].filter(Boolean).join(", ");
};

export const getAccountAddress = (account?: Account) => {
  const brandRegistration = account?.brandRegistration;
  if (!brandRegistration) return "";
  const { address1, city, postalCode, state } = brandRegistration;
  return [address1, city, state, postalCode].filter(Boolean).join(", ");
};
