import { useEffect, useState } from "react";
import { firebase, db } from "../firebase";

export const getDBUserByUID = async uid => {
  return await db
    .collection("users")
    .doc(uid)
    .get();
};

const useAuth = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async function(firebaseUser) {
      const location = window.location;
      if (location.search.includes("suspend=true")) {
        return;
      }
      if (firebaseUser) {
        const tokenResult = await firebaseUser.getIdTokenResult();
        const claims = tokenResult?.claims || {};
        const hasAccessToLogin = claims.role === "super-admin";
        if (!hasAccessToLogin) {
          await firebase.auth().signOut();
          setUser(null);
          setIsLoading(false);
          return;
        }
        const dbUser = await getDBUserByUID(firebaseUser.uid);
        if (!dbUser.exists) {
          await firebase.auth().signOut();
          setUser(null);
          setIsLoading(false);
          return;
        }
        setUser({
          ...dbUser.data(),
          id: dbUser.id,
          claims: tokenResult.claims
        });
        setIsLoading(false);
      } else {
        setUser(null);
        setIsLoading(false);
      }
    });
    return () => {
      firebase.auth().onAuthStateChanged(() => {});
    };
  }, [window.location.search]);

  return { user, isLoading };
};

export { useAuth };
