import { Navbar, Typography, Button, IconButton } from "@material-tailwind/react";
import { Bars3Icon, ArrowLeftStartOnRectangleIcon as LogoutIcon } from "@heroicons/react/24/solid";
import { setOpenSidenav, setSearchState, useAppContextController } from "context";
import { firebase } from "../firebase";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { routes } from "routes";
import { useForm } from "react-hook-form";
import FormWrapper from "components/Form/FormWrapper";
import { useCallback, useEffect, useState } from "react";
import { fetchAutocomplete, fetchSearchData } from "api/search";
import usePrevious from "hooks/usePrevious";
import _, { isEqual } from "lodash";
import Select from "react-select/async";
import { OptionProps, components } from "react-select";

enum OptionType {
  User = "user",
  Account = "account",
  Business = "business"
}

type SearchForm = {
  search: string;
};

export function HeaderNavbar() {
  const [controller, dispatch] = useAppContextController();
  const { openSidenav } = controller;

  const searchFormMethods = useForm<SearchForm>({
    defaultValues: {
      search: ""
    }
  });
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchData, setSearchData] = useState<any>(null);
  const [searchError, setSearchError] = useState<string>("");

  const logout = async () => await firebase.auth().signOut();

  const location = useLocation();
  const navigate = useNavigate();

  const route = Object.values(routes.app).find(({ path }) => path === location.pathname);

  const onSearch = async (values: SearchForm) => {
    try {
      setSearchError("");
      setIsSearchLoading(true);
      navigate({
        pathname: routes.app.accounts.path,
        search: new URLSearchParams(location.search).toString()
      });
      const data = await fetchSearchData(values.search);
      setSearchData(data);
    } catch (error) {
      setSearchError((error as any)?.message || "Something went wrong");
    } finally {
      setIsSearchLoading(false);
    }
  };

  useEffect(() => {
    setSearchState(dispatch, {
      data: searchData,
      isLoading: isSearchLoading,
      error: searchError
    });
  }, [searchData, isSearchLoading, searchError]);

  const prevLocation = usePrevious(location);

  useEffect(() => {
    if (location.pathname === routes.app.accounts.path && !isEqual(location.search, prevLocation?.search)) {
      const searchParams = new URLSearchParams(location.search);
      if (
        searchParams.has("email") ||
        searchParams.has("locationId") ||
        searchParams.has("accountId") ||
        searchParams.has("userId") ||
        searchParams.has("search")
      ) {
        const search =
          searchParams.get("email") ||
          searchParams.get("accountId") ||
          searchParams.get("locationId") ||
          searchParams.get("userId") ||
          searchParams.get("search") ||
          "";

        if (search) {
          onSearch({
            search
          });
        }
      }
    }
  }, [location?.search]);

  const loadOptions = async (inputValue: string) => {
    if (inputValue.length < 3) return [];
    try {
      const data = await fetchAutocomplete(inputValue);
      return [
        {
          label: "Accounts",
          options: data.accounts.map((account: any) => ({
            value: account.id,
            label: account.title,
            subtitle: account.id,
            type: OptionType.Account
          }))
        },
        {
          label: "Users",
          options: data.users.map((user: any) => ({
            value: user.id,
            label: user.title,
            subtitle: user.subtitle,
            type: OptionType.User
          }))
        },
        {
          label: "Businesses",
          options: data.businesses.map((business: any) => ({
            value: business.id,
            label: business.title,
            subtitle: business.id,
            type: OptionType.Business
          }))
        }
      ];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  function parseSearchString(searchString: string): string[] {
    // Check if the string contains spaces
    if (searchString.includes(" ")) {
      // If it does, split the string by spaces to get the individual terms
      return searchString.split(/\s+/);
    }
    // If there are no spaces, return an array containing the original string
    return [searchString];
  }

  const debouncedLoadOptions = useCallback(_.debounce(loadOptions, 300), []);

  return (
    <Navbar
      color={"white"}
      className="sticky top-4 z-40 rounded-xl border py-3 shadow-md shadow-blue-500/5 transition-all px-1 md:px-4"
      fullWidth
    >
      <div className="flex flex-col-reverse justify-between gap-6 md:flex-row md:items-center">
        <Typography className="hidden xl:grid" color="blue-gray" variant="lead">
          {route?.label || ""}
        </Typography>
        <div className="flex items-center xl:w-auto w-full">
          <IconButton
            variant="text"
            color="blue-gray"
            className="grid xl:hidden"
            onClick={() => setOpenSidenav(dispatch, !openSidenav)}
          >
            <Bars3Icon strokeWidth={3} className="h-6 w-6 text-blue-gray-500" />
          </IconButton>
          <div className="mr-auto xl:mr-4 w-64 sm:w-56">
            <FormWrapper
              formMethods={searchFormMethods}
              onSubmit={searchFormMethods.handleSubmit((data: SearchForm) => {
                navigate({
                  pathname: routes.app.accounts.path,
                  search: createSearchParams({ search: data.search }).toString()
                });
              })}
            >
              <Select
                loadOptions={debouncedLoadOptions}
                onInputChange={e => searchFormMethods.setValue("search", e)}
                onChange={e => {
                  if (!e) return;
                  const option = e as any;
                  const id = option.value;
                  const type = option.type;
                  const searchParams =
                    (type === OptionType.User ? "userId" : type === OptionType.Account ? "accountId" : "locationId") +
                    "=" +
                    id;
                  navigate({
                    pathname: routes.app.accounts.path,
                    search: searchParams
                  });
                }}
                components={{
                  Option: (props: OptionProps) => {
                    const data: any = props.data;
                    const searchTerms = parseSearchString(searchFormMethods.watch("search"));
                    return (
                      <components.Option {...props}>
                        <div className="flex flex-col items-start justify-center gap-2 text-blue-gray-800">
                          <Typography variant="h6">
                            <Highlighter
                              highlightClassName="bg-[#5468ff] bg-opacity-10 text-[#5468ff]"
                              searchWords={searchTerms}
                              autoEscape={true}
                              textToHighlight={data.label}
                            />
                          </Typography>
                          {data.subtitle && (
                            <Typography variant="small" color="blue-gray">
                              <Highlighter
                                highlightClassName="bg-[#5468ff] bg-opacity-10 text-[#5468ff]"
                                searchWords={searchTerms}
                                autoEscape={true}
                                textToHighlight={data.subtitle}
                              />
                            </Typography>
                          )}
                        </div>
                      </components.Option>
                    );
                  }
                }}
                placeholder="Search..."
                styles={{
                  placeholder: base => {
                    return {
                      ...base,
                      pointerEvents: "none",
                      userSelect: "none",
                      MozUserSelect: "none",
                      WebkitUserSelect: "none",
                      msUserSelect: "none"
                    };
                  },
                  input: base => ({
                    ...base,
                    // expand input area to fill all the available area
                    gridTemplateColumns: "0 minmax(min-content, 1fr)"
                  })
                }}
              />
            </FormWrapper>
          </div>
          <IconButton variant="text" color="blue-gray" onClick={() => navigate(routes.app.mfAuth.path)}>
            {routes.app.mfAuth.icon({ className: "h-5 w-5 text-blue-gray-500" })}
          </IconButton>
          <Button
            variant="text"
            color="blue-gray"
            className="hidden items-center gap-1 px-4 normal-case xl:flex"
            onClick={logout}
          >
            <LogoutIcon className="h-5 w-5 text-blue-gray-500" />
            Logout
          </Button>
          <IconButton variant="text" color="blue-gray" className="grid xl:hidden" onClick={logout}>
            <LogoutIcon className="h-5 w-5 text-blue-gray-500" />
          </IconButton>
        </div>
      </div>
    </Navbar>
  );
}

export default HeaderNavbar;
