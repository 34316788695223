import { Link, NavLink } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button, IconButton, Typography } from "@material-tailwind/react";
import { setOpenSidenav, useAppContextController } from "context";
import { routes } from "routes";

export function Sidenav() {
  const [controller, dispatch] = useAppContextController();
  const { openSidenav } = controller;

  return (
    <aside
      className={`bg-white shadow-sm ${
        openSidenav ? "translate-x-0" : "-translate-x-80"
      } fixed inset-0 z-50 my-4 ml-4 h-[calc(100vh-32px)] w-72 rounded-xl border border-blue-gray-100 transition-transform duration-300 xl:translate-x-0`}
    >
      <div className={`relative`}>
        <Link to="/" className="gap- my-5 flex w-full justify-start gap-2 pl-4 text-center">
          <img src={"/img/waitly-black-logo.png"} alt="logo" className="h-8" />
          <Typography variant="h6" color={"blue-gray"}>
            Customer Service Portal
          </Typography>
        </Link>
        <IconButton
          variant="text"
          color="blue-gray"
          size="sm"
          ripple={false}
          className="absolute right-0 top-0 grid rounded-br-none rounded-tl-none xl:hidden"
          onClick={() => setOpenSidenav(dispatch, false)}
        >
          <XMarkIcon strokeWidth={2.5} className="text-main h-5 w-5" />
        </IconButton>
      </div>
      <div className="m-4">
        {Object.keys(routes.app).map((_routeKey, key) => {
          const routeKey = _routeKey as keyof typeof routes.app;
          const { icon, label, path, displayInSidebar } = routes.app[routeKey];
          if (!displayInSidebar) {
            return null;
          }
          return (
            <ul key={key} className="mb-4 flex flex-col gap-1">
              <li key={label}>
                <NavLink to={`${path}`} onClick={() => setOpenSidenav(dispatch, false)}>
                  {({ isActive }) => (
                    <Button
                      variant={isActive ? "gradient" : "text"}
                      color={"black"}
                      className={"flex items-center gap-4 px-4 capitalize"}
                      fullWidth
                    >
                      {icon({ className: "h-5 w-5 " })}
                      <Typography color="inherit" className="font-medium capitalize">
                        {label}
                      </Typography>
                    </Button>
                  )}
                </NavLink>
              </li>
            </ul>
          );
        })}
      </div>
    </aside>
  );
}

export default Sidenav;
