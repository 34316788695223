import { Responses } from "types";
import { firebase } from "../firebase";

export const getUserTokenForImpersonate = async (userId: string): Promise<Responses["createCustomToken"]> => {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser?.getIdToken(true);
  const response = await fetch(`cs/token?userId=${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  });

  const dt = await response.json();
  if (!response.ok || (response.status > 200 && response.status < 600)) {
    throw new Error(dt.error || response.statusText);
  }
  return dt;
};

export const generateOTP = async (): Promise<Responses["generateOTP"]> => {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser?.getIdToken(true);
  const response = await fetch(`cs/generateOTP`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  });

  const dt = await response.json();
  if (!response.ok || (response.status > 200 && response.status < 600)) {
    throw new Error(dt.error || response.statusText);
  }
  return dt;
};

export const validateOTP = async (otpToken: string): Promise<Responses["validateOTP"]> => {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser?.getIdToken(true);
  const response = await fetch(`cs/validateOTP`, {
    method: "POST",
    body: JSON.stringify({ token: otpToken }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  });

  const dt = await response.json();
  if (!response.ok || (response.status > 200 && response.status < 600)) {
    throw new Error(dt.error || response.statusText);
  }
  return dt;
};
