import { HomeIcon, TableCellsIcon, UserIcon, ShieldCheckIcon } from "@heroicons/react/24/solid";
import AccountsPage from "pages/Accounts.page";
import DashboardPage from "pages/Dashboard.page";
import LoginPage from "pages/Login.page";
import MFAPage from "pages/MFA.page";
import PhoneNumbersPage from "pages/PhoneNumbers.page";

export const routes = {
  app: {
    dashboard: {
      path: "/dashboard",
      component: (props: any = {}) => <DashboardPage {...props} />,
      icon: (props: any = {}) => <HomeIcon {...props} />,
      label: "Dashboard",
      displayInSidebar: true
    },
    accounts: {
      path: "/accounts",
      component: (props: any = {}) => <AccountsPage {...props} />,
      icon: (props: any = {}) => <UserIcon {...props} />,
      label: "Accounts",
      displayInSidebar: true
    },
    phoneNumbers: {
      path: "/phone-numbers",
      component: (props: any = {}) => <PhoneNumbersPage {...props} />,
      icon: (props: any = {}) => <TableCellsIcon {...props} />,
      label: "Phone Numbers",
      displayInSidebar: true
    },
    mfAuth: {
      path: "/mf-auth",
      component: (props: any = {}) => <MFAPage {...props} />,
      icon: (props: any = {}) => <ShieldCheckIcon {...props} />,
      label: "Multi-Factor Auth",
      displayInSidebar: false
    }
  },
  auth: {
    login: {
      path: "/login",
      component: (props: any = {}) => <LoginPage {...props} />
    }
  }
};
