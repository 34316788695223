import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { Input } from "@material-tailwind/react";
import { ChangeEventHandler } from "react";

export const displayDate = (date: Date | string | Timestamp | undefined) => {
  if (!date) return "";
  if (date instanceof Date) return DateTime.fromJSDate(date).toFormat("MM/dd/yyyy");
  if (typeof date === "string") return DateTime.fromJSDate(new Date(date)).toFormat("MM/dd/yyyy");
  return DateTime.fromJSDate(
    new Timestamp(date.seconds || (date as any)._seconds, date.nanoseconds || (date as any)._nanoseconds).toDate()
  ).toFormat("MM/dd/yyyy");
};

export const displayInput = (id: string, label: string, onChange: ChangeEventHandler) => {
  return <Input id={id} variant="outlined" label={label} placeholder={label} onChange={onChange} />;
};
export const displayLink = ({ url, label, internal }: { url?: string; label?: string; internal: boolean }) => {
  if (!url) return label || "";

  if (internal) {
    return (
      <Link className="text-sm hover:underline text-blue-600 hover:text-light-blue-500" to={url}>
        {label}
      </Link>
    );
  }

  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer noopener"
      className="text-sm hover:underline text-blue-600 hover:text-light-blue-500"
    >
      {label}
    </a>
  );
};

export const displayBoolean = (value: boolean | undefined) => {
  return value ? "Yes" : "No";
};
