import { Typography } from "@material-tailwind/react";
import { FormProvider, SubmitHandler, UseFormReturn } from "react-hook-form";

type FormWrapperProps = {
  children: React.ReactNode;
  formMethods: UseFormReturn<any, any>;
  onSubmit: SubmitHandler<any>;
  className?: React.HTMLAttributes<HTMLFormElement>["className"];
  error?: string;
};

const FormWrapper = ({ children, formMethods, onSubmit, className = "", error = "" }: FormWrapperProps) => {
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} className={className}>
        {children}
      </form>
      {error && (
        <Typography variant="small" color="red" className="mt-3 text-center">
          {error}
        </Typography>
      )}
    </FormProvider>
  );
};

export default FormWrapper;
