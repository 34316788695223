import { Typography } from "@material-tailwind/react";
import { useClsx } from "hooks/useClsx";

type TableProps<T> = {
  columns: Array<{
    header: string;
    accessor: (item: T) => string | React.ReactNode;
  }>;
  onRowDoubleClick?: (item: T) => void;
  items: T[];
};

const Table = <T,>({ columns, items, onRowDoubleClick }: TableProps<T>) => {
  return (
    <div className="overflow-x-scroll px-0 pb-2 pt-0">
      <table className="w-full min-w-[640px] table-auto">
        <thead>
          <tr>
            {columns.map((column, idx) => (
              <th key={column.header + idx} className="border-b border-blue-gray-50 px-5 py-3 text-left">
                <Typography variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400">
                  {column.header}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item, key) => {
            const className = `py-3 px-5 ${key === items.length - 1 ? "" : "border-b border-blue-gray-50"}`;

            return (
              <tr
                key={key}
                onDoubleClick={() => onRowDoubleClick?.(item)}
                className={useClsx({
                  "hover:cursor-pointer hover:bg-blue-gray-50": Boolean(onRowDoubleClick)
                })}
              >
                {columns.map((column, idx) => (
                  <td key={idx} className={className}>
                    <Typography className="text-xs font-semibold text-blue-gray-600">
                      {column.accessor(item) || ""}
                    </Typography>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
