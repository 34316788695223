import Footer from "components/Footer";
import Sidenav from "components/Sidenav";
import HeaderNavbar from "components/HeaderNavbar";

const AppLayout = ({ children }) => {
  return (
    <div className="min-h-screen bg-blue-gray-50/50">
      <Sidenav />
      <div className="p-4 xl:ml-80">
        <HeaderNavbar />
        {children}
        <div className="text-blue-gray-600">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
