import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export const useFetch = <T,>(query: () => T, deps?: React.DependencyList | undefined) => {
  const [data, setData] = useState<Awaited<T> | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);

  const handle = async () => {
    try {
      setIsLoading(true);
      const res = await query();
      setData(res);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handle();
  }, deps);

  useEffect(() => {
    if (error) {
      toast.error(String(error));
    }
  }, [error]);

  return { data, isLoading, error };
};
