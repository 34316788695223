import { firebase } from "../firebase";

export const submitVerify = async (id: string, additionalInfo: string = "") => {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser?.getIdToken(true);
  const response = await fetch(`cs/verify`, {
    body: JSON.stringify({ id, additionalInfo }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  });

  const dt = await response.json();
  if (!response.ok || (response.status > 200 && response.status < 600)) {
    throw new Error(dt.error || response.statusText);
  }

  return;
};
