import { Typography } from "@material-tailwind/react";

export const CardEmptyState = ({ message }: { message: string }) => {
  return (
    <div className="flex min-h-96 items-center justify-center">
      <Typography variant="h5" color="blue-gray" className="my-4">
        {message}
      </Typography>
    </div>
  );
};
