import { Card, CardHeader, CardBody, CardFooter, Typography, Spinner } from "@material-tailwind/react";
import classNames from "classnames";
import Chart from "react-apexcharts";
import { Props as IChart } from "react-apexcharts";

export function StatisticsChart({
  color,
  chart,
  title,
  description,
  footer,
  isLoading
}: {
  color: any;
  chart: IChart;
  title: string;
  description?: string;
  footer?: any;
  isLoading?: boolean;
}) {
  return (
    <Card className="border border-blue-gray-100 shadow-sm">
      <CardHeader variant="gradient" color={color} floated={false} shadow={false} className="z-10 overflow-visible">
        {isLoading ? (
          <div className="w-80 h-60 flex justify-center items-center">
            <Spinner width={40} height={40} />
          </div>
        ) : (
          <Chart {...chart} />
        )}
      </CardHeader>
      <CardBody
        className={classNames({
          "px-6 pt-0": true,
          "pb-2": !footer && !description
        })}
      >
        <Typography variant="h6" color="blue-gray">
          {title}
        </Typography>
        {!!description && (
          <Typography variant="small" className="font-normal text-blue-gray-600">
            {description}
          </Typography>
        )}
      </CardBody>
      {footer && <CardFooter className="border-t border-blue-gray-50 px-6 py-5">{footer}</CardFooter>}
    </Card>
  );
}

StatisticsChart.defaultProps = {
  color: "blue",
  footer: null
};

StatisticsChart.displayName = "/src/widgets/charts/statistics-chart.jsx";

export default StatisticsChart;
