import { Responses } from "types";
import { firebase } from "../firebase";

export const fetchSearchData = async (search: string): Promise<Responses["csSearchAccount"]> => {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser?.getIdToken(true);
  const response = await fetch(`cs/search`, {
    body: JSON.stringify({ q: search }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  });

  const dt = await response.json();
  if (!response.ok || (response.status > 200 && response.status < 600)) {
    throw new Error(dt.error || response.statusText);
  }
  return dt;
};

export const fetchAutocomplete = async (search: string): Promise<Responses["csAutocomplete"]> => {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser?.getIdToken(true);
  const response = await fetch(`cs/authcomplete`, {
    body: JSON.stringify({ q: search }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  });

  const dt = await response.json();
  if (!response.ok || (response.status > 200 && response.status < 600)) {
    throw new Error(dt.error || response.statusText);
  }
  return dt;
};
