import { Tab, Tabs, TabsHeader, Typography } from "@material-tailwind/react";

export const CustomCardHeader = ({
  title,
  subtitle,
  tab,
  setTab,
  tabs,
  headerSubContent
}: {
  title: string;
  subtitle?: string;
  tab: string;
  setTab: (tab: any) => void;
  tabs: Array<{
    value: string;
    label: string;
    Icon: React.ForwardRefExoticComponent<
      Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
        title?: string | undefined;
        titleId?: string | undefined;
      } & React.RefAttributes<SVGSVGElement>
    >;
  }>;
  headerSubContent?: React.ReactNode;
}) => {
  return (
    <div className="mb-10 flex flex-wrap items-center justify-between gap-6">
      <div className="flex items-center gap-6">
        <div>
          <Typography variant="h5" color="blue-gray" className="mb-1">
            {title}
          </Typography>
          <Typography variant="small" className="font-normal text-blue-gray-600">
            {subtitle || ""}
          </Typography>
          {headerSubContent}
        </div>
      </div>
      <div className="w-1/2">
        <Tabs value={tab}>
          <TabsHeader>
            {tabs.map(({ value, label, Icon }) => (
              <Tab key={value} value={value} onClick={() => setTab(value)}>
                <Icon className="-mt-1 mr-2 inline-block h-5 w-5" />
                <span className="hidden sm:inline-block">{label}</span>
              </Tab>
            ))}
          </TabsHeader>
        </Tabs>
      </div>
    </div>
  );
};
