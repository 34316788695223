import { db } from "firebase";
import { Observable } from "rxjs";
import { Account } from "types";

export const getPaidSubscribers = async (): Promise<Account[]> => {
  const accounts = await db
    .collection("accounts")
    .where("plan", "in", ["premium", "pro", "enterprise"])
    .where("status", "==", "active")
    .get();
  return accounts.docs.map(doc => {
    return {
      id: doc.id,
      ...doc.data()
    };
  }) as Account[];
};

export const getActiveTrials = async (): Promise<Account[]> => {
  const accounts = await db
    .collection("accounts")
    .where("plan", "in", ["premium", "pro", "enterprise"])
    .where("status", "==", "trialing")
    .get();
  return accounts.docs.map(doc => {
    return {
      id: doc.id,
      ...doc.data()
    };
  }) as Account[];
};

export const getDashboardRealtimeStats = () => {
  return new Observable(subscriber => {
    db.collection("aggregates").onSnapshot(result => {
      subscriber.next(result.docs);
    });
  });
};
