import { useEffect, useState } from "react";
import { StatisticsCard } from "components/Cards/StatisticsCard";
import {
  BanknotesIcon,
  BuildingStorefrontIcon,
  ClockIcon,
  DocumentCheckIcon,
  UserPlusIcon
} from "@heroicons/react/24/solid";
import { getActiveTrials, getDashboardRealtimeStats, getPaidSubscribers } from "api/dashboard";
import { PieChart } from "components/Cards/PieChart";
import { Aggregate } from "types";
import { Card, CardBody, Spinner } from "@material-tailwind/react";
import { FunnelIcon } from "@heroicons/react/24/outline";
import FormWrapper from "components/Form/FormWrapper";
import { useForm } from "react-hook-form";
import FormInput from "components/Form/FormInput";
import _ from "lodash";
import { useDeepCompareEffect } from "hooks/useDeepCompareEffect";
import { DateTime } from "luxon";
import { Timestamp } from "firebase/firestore";
import { DashboardMap } from "components/Dashboard/DashboardMap";
import PullToRefresh from "react-simple-pull-to-refresh";

enum FilterDateOptions {
  Today = "today",
  Last30Mins = "last30mins"
}

const filterDateOptions = [
  { value: FilterDateOptions.Today, label: "Today" },
  {
    value: FilterDateOptions.Last30Mins,
    label: "Last 30 minutes"
  }
];

type IForm = {
  date: typeof filterDateOptions[number];
  includeTrulieve: boolean;
};

const filterAggregateSnapshots = (snapshots: Aggregate[], form: IForm) => {
  return snapshots.filter(snapshot => {
    if (!form.includeTrulieve) {
      if (["xj5spEZSQb2wd0nFRKY6", "IgBNLUJQqJwa1URhwUAX"].includes(snapshot.accountId)) {
        return false;
      }
    }
    if (form.date.value === FilterDateOptions.Last30Mins) {
      const seconds30minsAgo = DateTime.now()
        .minus({ minutes: 30 })
        .toSeconds();
      return (
        snapshot.lastUpdated &&
        DateTime.fromJSDate((snapshot.lastUpdated as Timestamp).toDate()).toSeconds() >= seconds30minsAgo
      );
    }
    return true;
  });
};

const DashboardPage = () => {
  const [isLoadingStatistics, setIsLoadingStatisticts] = useState(true);
  const [dashboardRealtimeStats, setDashboardRealtimeStats] = useState<Array<Aggregate>>([]);
  const [statistics, setStatistics] = useState({
    boxes: {
      paidSubscribers: 0,
      activeTrials: 0
    },
    charts: {
      subscribersByPlan: {
        premium: 0,
        pro: 0,
        enterprise: 0
      },
      subscribersByOrigin: {
        square: 0,
        stripe: 0,
        ios: 0
      },
      trialsByOrigin: {
        square: 0,
        stripe: 0,
        ios: 0
      },
      trialsByPlan: {
        premium: 0,
        pro: 0,
        enterprise: 0
      }
    }
  });

  const formMethods = useForm<IForm>({
    defaultValues: {
      date: filterDateOptions[0],
      includeTrulieve: false
    }
  });

  const fetchStatistics = async () => {
    setIsLoadingStatisticts(true);
    const [paidSubscribers, activeTrials] = await Promise.all([getPaidSubscribers(), getActiveTrials()]);

    const [premiumSubscribers, proSubscribers, enterpriseSubscribers] = [
      [...paidSubscribers].filter(s => s.plan === "premium"),
      [...paidSubscribers].filter(s => s.plan === "pro"),
      [...paidSubscribers].filter(s => s.plan === "enterprise")
    ];

    const [iosSubscribers, squareSubscribers, stripeSubscribers] = [
      [...paidSubscribers].filter(s => s.origin === "ios"),
      [...paidSubscribers].filter(s => s.origin === "square"),
      [...paidSubscribers].filter(s => s.origin === "stripe")
    ];

    const [iosTrials, squareTrials, stripeTrials] = [
      [...activeTrials].filter(s => s.origin === "ios"),
      [...activeTrials].filter(s => s.origin === "square"),
      [...activeTrials].filter(s => s.origin === "stripe")
    ];

    const [premiumTrials, proTrials, enterpriseTrials] = [
      [...activeTrials].filter(s => s.plan === "premium"),
      [...activeTrials].filter(s => s.plan === "pro"),
      [...activeTrials].filter(s => s.plan === "enterprise")
    ];

    setStatistics({
      ...statistics,
      boxes: {
        paidSubscribers: paidSubscribers.length,
        activeTrials: activeTrials.length
      },
      charts: {
        ...statistics.charts,
        subscribersByPlan: {
          premium: premiumSubscribers.length,
          pro: proSubscribers.length,
          enterprise: enterpriseSubscribers.length
        },
        subscribersByOrigin: {
          ios: iosSubscribers.length,
          square: squareSubscribers.length,
          stripe: stripeSubscribers.length
        },
        trialsByOrigin: {
          ios: iosTrials.length,
          square: squareTrials.length,
          stripe: stripeTrials.length
        },
        trialsByPlan: {
          premium: premiumTrials.length,
          pro: proTrials.length,
          enterprise: enterpriseTrials.length
        }
      }
    });

    setIsLoadingStatisticts(false);
  };

  useEffect(() => {
    fetchStatistics();
  }, []);

  useDeepCompareEffect(() => {
    const subscriber = getDashboardRealtimeStats().subscribe((dashboardStats: any) => {
      const parsedSnapshots = dashboardStats.map(doc => ({ id: doc.id, ...doc.data() }));
      const filteredSnapshots = filterAggregateSnapshots(parsedSnapshots, formMethods.watch());
      setDashboardRealtimeStats(filteredSnapshots);
    });

    return () => {
      subscriber.unsubscribe();
    };
  }, [formMethods.watch()]);

  return (
    <PullToRefresh
      onRefresh={fetchStatistics}
      pullingContent={
        <div className="flex flex-col items-center justify-center mt-4">
          <h1 className="text-blue-gray-500">Pull down to refresh</h1>
        </div>
      }
      refreshingContent={
        <div className="flex items-center justify-center mt-2 gap-4">
          <Spinner width={30} height={30} />
          <h1 className="text-blue-gray-500">Refreshing...</h1>
        </div>
      }
    >
      <div className="mt-12 mb-6 flex flex-col gap-y-6">
        <div className="grid gap-6 md:grid-cols-2">
          <StatisticsCard
            title={"Paid Subscribers"}
            icon={<BanknotesIcon className="w-6 h-6 text-white" />}
            value={statistics.boxes.paidSubscribers.toLocaleString("en-US")}
            color={"gray"}
            isLoading={isLoadingStatistics}
          />
          <StatisticsCard
            title={"Active Trials"}
            icon={<UserPlusIcon className="w-6 h-6 text-white" />}
            value={statistics.boxes.activeTrials.toLocaleString("en-US")}
            color={"gray"}
            isLoading={isLoadingStatistics}
          />
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <PieChart
            title="Subscribers by Plan"
            series={[
              statistics.charts.subscribersByPlan.premium,
              statistics.charts.subscribersByPlan.pro,
              statistics.charts.subscribersByPlan.enterprise
            ]}
            colors={["#388e3c", "#0288d1", "#f44336"]}
            labels={["Premium", "Pro", "Enterprise"]}
            isLoading={isLoadingStatistics}
          />
          <PieChart
            title="Trials by Plan"
            series={[
              statistics.charts.trialsByPlan.premium,
              statistics.charts.trialsByPlan.pro,
              statistics.charts.trialsByPlan.enterprise
            ]}
            colors={["#388e3c", "#0288d1", "#f44336"]}
            labels={["Premium", "Pro", "Enterprise"]}
            isLoading={isLoadingStatistics}
          />
          <PieChart
            title="Subscribers by Origin"
            series={[
              statistics.charts.subscribersByOrigin.ios,
              statistics.charts.subscribersByOrigin.stripe,
              statistics.charts.subscribersByOrigin.square
            ]}
            colors={["#B2B2BA", "#675DFF", "#000000"]}
            labels={["iOS", "Stripe", "Square"]}
            isLoading={isLoadingStatistics}
          />
          <PieChart
            title="Trials by Origin"
            series={[
              statistics.charts.trialsByOrigin.ios,
              statistics.charts.trialsByOrigin.stripe,
              statistics.charts.trialsByOrigin.square
            ]}
            colors={["#B2B2BA", "#675DFF", "#000000"]}
            labels={["iOS", "Stripe", "Square"]}
            isLoading={isLoadingStatistics}
          />
        </div>
        <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-3">
          <StatisticsCard
            title={"Active Locations"}
            icon={<BuildingStorefrontIcon className="w-6 h-6 text-white" />}
            value={dashboardRealtimeStats.length.toLocaleString("en-US")}
            color={"gray"}
            isLoading={false}
          />
          <StatisticsCard
            title={"Waiting"}
            icon={<ClockIcon className="w-6 h-6 text-white" />}
            value={dashboardRealtimeStats.reduce((sum, curr) => (sum += curr.waiting || 0), 0).toLocaleString("en-US")}
            color={"gray"}
            isLoading={false}
          />
          <StatisticsCard
            title={"Served"}
            icon={<DocumentCheckIcon className="w-6 h-6 text-white" />}
            value={dashboardRealtimeStats.reduce((sum, curr) => (sum += curr.served || 0), 0).toLocaleString("en-US")}
            color={"gray"}
            isLoading={false}
          />
        </div>
        <Card className="border border-blue-gray-100 shadow-sm">
          <CardBody>
            <FormWrapper
              className="flex w-full gap-x-4 justify-start items-center"
              onSubmit={e => {
                e.preventDefault();
              }}
              formMethods={formMethods}
            >
              <FunnelIcon className="w-6 h-6 text-blue-gray-500" />
              <FormInput<IForm> name="date" type="select" options={filterDateOptions} />
              <FormInput<IForm> name="includeTrulieve" type="switch" />
            </FormWrapper>
          </CardBody>
        </Card>
        <DashboardMap dashboardRealtimeStats={dashboardRealtimeStats} />
      </div>
    </PullToRefresh>
  );
};

export default DashboardPage;
