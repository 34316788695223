import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { routes } from "routes";
import AppLayout from "layouts/App.layout";
import { Spinner } from "@material-tailwind/react";

function App() {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="flex h-screen w-screen flex-row items-center justify-center gap-4 p-10 text-center text-3xl">
        <Spinner width={40} height={40} />
        <span className="block">Loading...</span>
      </div>
    );
  }

  if (!user) {
    return (
      <Routes>
        {Object.keys(routes.auth).map((key, idx) => {
          const route = routes.auth[key];
          const routeProps = {};
          return <Route key={key + idx} path={route.path} element={route.component(routeProps)} />;
        })}
        <Route path="*" element={<Navigate to={routes.auth.login.path} />} />
      </Routes>
    );
  }

  return (
    <AppLayout>
      <Routes>
        {Object.keys(routes.app).map((_key, idx) => {
          const key = _key as keyof typeof routes.app;
          const route = routes.app[key];
          const routeProps = {
            user
          };
          return <Route key={key + idx} path={route.path} element={route.component(routeProps)} />;
        })}
        <Route path="*" element={<Navigate to={routes.app.dashboard.path} />} />
      </Routes>
    </AppLayout>
  );
}

export default App;
