import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import { generateOTP } from "api/auth";
import FormInput from "components/Form/FormInput";
import FormWrapper from "components/Form/FormWrapper";
import { db } from "firebase";
import usePrevious from "hooks/usePrevious";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { User } from "types";
import QRCode from "qrcode";
import { toast } from "react-toastify";
import MFAModal from "components/MFA/MFAModal";
import MFAConfirmDisableModal from "components/MFA/MFAConfirmDisableModal";

type MFAForm = {
  isMFAEnabled: boolean;
};

type MFAPageProps = {
  user: User;
};

const updateMFAStatus = async (isMFAEnabled: boolean, userId: string) => {
  await db
    .collection("users")
    .doc(userId)
    .set(
      {
        csSettings: {
          isMFAEnabled,
          isMFAVerified: false
        }
      },
      {
        merge: true
      }
    );
};

const MFAPage = (props: MFAPageProps) => {
  const prevUser = usePrevious(props.user);
  const [qrCode, setQRCode] = useState<string>("");
  const [base32, setBase32] = useState<string>("");
  const [isLoadingOTPGeneration, setIsLoadingOTPGeneration] = useState(false);
  const [OTPModal, setOTPModal] = useState({
    isOpen: false,
    onlyView: false,
    shouldReset: false
  });
  const [isOpenMFADisableModal, setIsOpenMFADisableModal] = useState(false);

  const getDefaultValues = () => {
    return {
      isMFAEnabled: !!props.user.csSettings?.isMFAEnabled
    };
  };

  const formMethods = useForm<MFAForm>({
    defaultValues: getDefaultValues()
  });

  useEffect(() => {
    if (props.user.csSettings?.isMFAEnabled && !props.user.csSettings?.isMFAVerified) {
      updateMFAStatus(false, props.user.id);
      formMethods.setValue("isMFAEnabled", false);
    }
    if (!props.user.csSettings?.isMFAEnabled && props.user.csSettings?.isMFAVerified) {
      formMethods.setValue("isMFAEnabled", false);
      updateMFAStatus(false, props.user.id);
    }
  }, []);

  useEffect(() => {
    if (!isEqual(props.user, prevUser)) {
      formMethods.reset(getDefaultValues());
      const csSettings = props.user.csSettings;
      if (csSettings?.isMFAEnabled && csSettings?.isMFAVerified) {
        setBase32(csSettings.otpBase32 || "");
        QRCode.toDataURL(csSettings.otpAuthUrl || "").then(setQRCode);
      }
    }
  }, [props.user]);

  useEffect(() => {
    if (OTPModal.shouldReset) {
      formMethods.setValue("isMFAEnabled", false);
      setBase32("");
      setQRCode("");
      setOTPModal(prev => ({
        isOpen: prev.isOpen,
        shouldReset: false,
        onlyView: prev.onlyView
      }));
    }
  }, [OTPModal.shouldReset]);

  const handleGenerateOTP = async () => {
    try {
      setIsLoadingOTPGeneration(true);
      const response = await generateOTP();
      if (response.otpAuthUrl) {
        const qrUrl = await QRCode.toDataURL(response.otpAuthUrl);
        setQRCode(qrUrl);
      }
      if (response.base32) {
        setBase32(response.base32);
      }
      setOTPModal({
        isOpen: true,
        onlyView: false,
        shouldReset: false
      });
    } catch (error) {
      formMethods.setValue("isMFAEnabled", false);
      updateMFAStatus(false, props.user.id);
      toast.error((error as any)?.message || "Failed to generate OTP");
      console.error("🚀 ~ handleGenerateOTP ~ error", error);
    } finally {
      setIsLoadingOTPGeneration(false);
    }
  };

  const handleShowExistingQRCode = () => {
    setOTPModal({
      isOpen: true,
      onlyView: true,
      shouldReset: false
    });
  };

  const handleMFADisable = () => {
    setIsOpenMFADisableModal(true);
  };

  return (
    <>
      <MFAConfirmDisableModal
        isOpen={isOpenMFADisableModal}
        setIsOpen={setIsOpenMFADisableModal}
        setInputValue={value => formMethods.setValue("isMFAEnabled", value)}
        updateMFAStatus={status => updateMFAStatus(status, props.user.id)}
      />
      <MFAModal
        base32={base32}
        qrCode={qrCode}
        isOpen={OTPModal.isOpen}
        setIsOpen={open =>
          setOTPModal(prev => ({
            isOpen: open.open,
            shouldReset: !!open.shouldReset,
            onlyView: prev.onlyView
          }))
        }
        onlyView={OTPModal.onlyView}
      />
      <div className="relative mt-8 h-32 w-full overflow-hidden rounded-xl bg-[url('/img/background-image.png')] bg-cover	bg-center">
        <div className="absolute inset-0 h-full w-full bg-gray-900/75" />
      </div>
      <Card className="mx-3 -mt-16 mb-6 border border-blue-gray-100 lg:mx-4">
        <CardBody className="p-4">
          <FormWrapper formMethods={formMethods} onSubmit={console.log}>
            <FormInput<MFAForm>
              containerClassName="w-56"
              name="isMFAEnabled"
              label="Multi-Factor Auth"
              type="switch"
              isLoading={isLoadingOTPGeneration}
              isDisabled={isLoadingOTPGeneration}
              onChange={e => {
                if (e?.target?.checked) {
                  updateMFAStatus(true, props.user.id);
                  handleGenerateOTP();
                } else {
                  if (props.user.csSettings?.isMFAVerified) {
                    handleMFADisable();
                  } else {
                    updateMFAStatus(false, props.user.id);
                  }
                }
              }}
            />
            {props.user.csSettings?.isMFAEnabled && props.user.csSettings?.isMFAVerified ? (
              <div className="mt-2">
                <Typography>
                  Your account is already protected with 2FA. But if you to view the QR code again, you can check it out
                  below
                </Typography>
                <Button
                  size="sm"
                  onClick={() => {
                    handleShowExistingQRCode();
                  }}
                >
                  Display QR Code
                </Button>
              </div>
            ) : (
              <Typography>
                Your account is not protected with 2FA. If you want to enable it, you can do it by switching the button
              </Typography>
            )}
          </FormWrapper>
        </CardBody>
      </Card>
    </>
  );
};

export default MFAPage;
