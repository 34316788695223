import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import "firebase/compat/auth";
import { environment } from "./configs/environment";

firebase.initializeApp(environment.config);
firebase.analytics();

const db = firebase.firestore();

export { db, firebase };
