import { Checkbox, Input, Spinner, Switch, Typography } from "@material-tailwind/react";
import { size as InputSize, labelProps as InputLabelProps } from "@material-tailwind/react/types/components/input";
import { useClsx } from "hooks/useClsx";
import { Path, useFormContext } from "react-hook-form";
import ReactSelect from "react-select";
import { camelCaseToSeparateWords, capitalize } from "util/input";

type FormInputProps<T> = {
  label?: string;
  name: Path<T>;
  isLoading?: boolean;
  isDisabled?: boolean;
  containerClassName?: React.HTMLAttributes<HTMLFormElement>["className"];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & (
  | {
      type: "text" | "email" | "password";
      size?: InputSize;
      className?: React.HTMLAttributes<HTMLFormElement>["className"];
      labelProps?: InputLabelProps;
      placeholder?: string;
    }
  | {
      type: "switch";
    }
  | {
      type: "checkbox";
    }
  | {
      type: "select";
      options: { value: string | number; label: string | number }[];
    }
);

const FormInput = <T,>({
  label: labelFromProps,
  name,
  isLoading,
  isDisabled,
  onChange,
  containerClassName: containerClassNameFropProps,
  ...props
}: FormInputProps<T>) => {
  const formMethods = useFormContext();
  const label = labelFromProps ?? camelCaseToSeparateWords(capitalize(name as string));
  const containerClassName = useClsx({
    relative: true,
    [containerClassNameFropProps!]: containerClassNameFropProps
  });

  const getInput = () => {
    switch (props.type) {
      case "text":
      case "email":
      case "password":
        return (
          <Input
            type={props.type}
            name={name}
            label={label}
            value={formMethods.watch(name)}
            onChange={e => {
              formMethods.setValue(name, e.target.value as any);
              onChange?.(e);
            }}
            disabled={isLoading || isDisabled}
            error={!!formMethods.formState.errors[name]}
            size={props.size}
            className={props.className || ""}
            labelProps={props.labelProps}
            placeholder={props.placeholder}
          />
        );
      case "switch":
        return (
          <Switch
            name={name}
            label={label}
            checked={formMethods.watch(name)}
            onChange={e => {
              formMethods.setValue(name, e.target.checked as any);
              onChange?.(e);
            }}
            disabled={isLoading || isDisabled}
          />
        );
      case "checkbox":
        return (
          <Checkbox
            name={name}
            label={label}
            checked={formMethods.watch(name)}
            onChange={e => {
              formMethods.setValue(name, e.target.checked as any);
              onChange?.(e);
            }}
            disabled={isLoading || isDisabled}
          />
        );
      case "select":
        return (
          <ReactSelect
            className="w-52"
            name={name}
            value={formMethods.watch(name)}
            onChange={e => {
              formMethods.setValue(name, e as any);
              onChange?.(e as any);
            }}
            isDisabled={isLoading || isDisabled}
            isLoading={isLoading}
            placeholder={label}
            options={props.options as any}
          />
        );
    }
  };

  return (
    <div className={containerClassName}>
      {getInput()}
      {isLoading && <Spinner className="absolute bottom-0 right-4 top-0 my-auto" />}
      {formMethods.formState.errors[name] && (
        <Typography color="red" variant="small" className="mt-1">
          {(formMethods.formState.errors[name]?.message as any) || ""}
        </Typography>
      )}
    </div>
  );
};

export default FormInput;
