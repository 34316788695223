import { Card, CardBody, Spinner } from "@material-tailwind/react";
import { GoogleMap, InfoWindowF, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { environment } from "configs/environment";
import { dashboardMapStyle } from "data/dashboard-map-styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { Aggregate } from "types";

const GOOGLE_MAPS_API_KEY = environment.googleMapsKey;

const mapContainerStyle = {
  width: "100%",
  height: "600px"
};

const center = {
  lat: 42,
  lng: -100
};

export type MarkerType = {
  id: string;
  lat: number;
  lng: number;
  title: string;
  served: number;
  waiting: number;
  isTrulieve?: boolean;
};

export const DashboardMap = ({ dashboardRealtimeStats }: { dashboardRealtimeStats: Aggregate[] }) => {
  const [activeMarkers, setActiveMarkers] = useState<MarkerType[]>([]);
  const [markerInfoModalGeo, setMarkerInfoModalGeo] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
  const [markers, setMarkers] = useState<MarkerType[]>([]);

  const navigate = useNavigate();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  });

  const handleMarkerEnter = (mrkrs: MarkerType[], geo) => {
    setActiveMarkers(mrkrs);
    setMarkerInfoModalGeo(geo);
  };

  const handleCloseMarkerInfoModal = () => {
    setActiveMarkers([]);
  };

  const handleMouseOut = e => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setActiveMarkers([]);
    }
  };

  // Adds a mouseout listener to the InfoWindow content
  const onInfoWindowLoad = infoWindow => {
    const infoWindowContent = infoWindow.getContent();
    if (infoWindowContent) {
      console.log(infoWindowContent);
      infoWindowContent.addEventListener("mouseout", handleMouseOut);
    }
  };

  // Removes the mouseout listener from the InfoWindow content
  const onInfoWindowUnmount = infoWindow => {
    const infoWindowContent = infoWindow.getContent();
    if (infoWindowContent) {
      infoWindowContent.removeEventListener("mouseout", handleMouseOut);
    }
  };

  const getScale = (location: MarkerType) => {
    const served = location.served;
    const waiting = location.waiting;
    const total = served + waiting;
    const maxServedSize = 1000;
    const servedSize = Math.max(1, Math.min(total, maxServedSize));

    const minScale = 4;
    const maxScale = 8;

    const scale = minScale + (Math.log(servedSize) / Math.log(maxServedSize)) * (maxScale - minScale);

    return scale;
  };

  const getColor = (location: MarkerType) => {
    if (location.isTrulieve) {
      return "rgb(38, 96, 65)";
    } else {
      return "rgb(0, 42, 183)";
    }
  };

  useEffect(() => {
    if (isLoaded && window.google) {
      const aggregatesWithLocation: Array<Aggregate & {
        address: { geo: { lat: number; lng: number } };
      }> = [...dashboardRealtimeStats].filter(
        aggregate => aggregate.address?.geo?.lat && aggregate.address?.geo?.lng
      ) as any;
      setMarkers(
        aggregatesWithLocation.map(aggregate => ({
          id: aggregate.id,
          lat: aggregate.address.geo.lat,
          lng: aggregate.address.geo.lng,
          title: aggregate.locationName,
          served: aggregate.partiesServed + aggregate.reservationsServed,
          waiting: aggregate.waiting,
          isTrulieve: aggregate.accountId === "xj5spEZSQb2wd0nFRKY6"
        }))
      );
    }
  }, [isLoaded, dashboardRealtimeStats]);

  return (
    <Card className="border border-blue-gray-100 shadow-sm">
      <CardBody>
        {!isLoaded && (
          <div className="w-full flex justify-end">
            <Spinner width={20} height={20} />
          </div>
        )}
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={4}
            options={{ styles: dashboardMapStyle }}
          >
            {markers.map(location => (
              <MarkerF
                key={location.id}
                position={{ lat: location.lat, lng: location.lng }}
                animation={window.google.maps.Animation.DROP}
                icon={{
                  path: window.google.maps.SymbolPath.CIRCLE,
                  fillColor: getColor(location),
                  fillOpacity: 0.6,
                  strokeWeight: 0,
                  scale: getScale(location)
                }}
                onMouseOver={() => handleMarkerEnter([location], { lat: location.lat, lng: location.lng })}
                onClick={() => handleMarkerEnter([location], { lat: location.lat, lng: location.lng })}
                onLoad={marker => marker.set("custom_id", location.id)}
              />
            ))}
            {!!activeMarkers.length && (
              <InfoWindowF
                position={{
                  lat: markerInfoModalGeo.lat,
                  lng: markerInfoModalGeo.lng
                }}
                onCloseClick={handleCloseMarkerInfoModal}
                onLoad={onInfoWindowLoad} // Add event listener when InfoWindow loads
                onUnmount={onInfoWindowUnmount} // Clean up event listener when InfoWindow is removed
              >
                <div style={{ padding: 10 }}>
                  {activeMarkers.map(marker => (
                    <React.Fragment key={marker.id}>
                      <span
                        style={{ padding: 4 }}
                        className="cursor-alias text-blue-500 flex items-center gap-1"
                        onClick={() => {
                          navigate({
                            pathname: routes.app.accounts.path,
                            search: `?locationId=${marker.id}`
                          });
                        }}
                      >
                        {marker.title}
                      </span>
                      <div style={{ padding: 4 }}>Waiting: {marker.waiting}</div>
                      <div style={{ padding: 4 }}>Served: {marker.served}</div>
                    </React.Fragment>
                  ))}
                </div>
              </InfoWindowF>
            )}
          </GoogleMap>
        )}
      </CardBody>
    </Card>
  );
};
