import { Card, CardHeader, CardBody, CardFooter, Typography, Spinner } from "@material-tailwind/react";

export function StatisticsCard({ color, icon, title, value, footer, isLoading = false }) {
  return (
    <Card className="border border-blue-gray-100 shadow-sm">
      <CardHeader
        variant="gradient"
        color={color}
        floated={false}
        shadow={false}
        className="absolute grid h-12 w-12 place-items-center"
      >
        {icon}
      </CardHeader>
      <CardBody className="p-4 text-right">
        <Typography variant="small" className="font-normal text-blue-gray-600">
          {title}
        </Typography>
        {isLoading ? (
          <div className="w-full flex justify-end">
            <Spinner width={20} height={20} />
          </div>
        ) : (
          <Typography variant="h4" color="blue-gray">
            {value}
          </Typography>
        )}
      </CardBody>
      {footer && <CardFooter className="border-t border-blue-gray-50 p-4">{footer}</CardFooter>}
    </Card>
  );
}

StatisticsCard.defaultProps = {
  color: "blue",
  footer: null
};
export default StatisticsCard;
