import React from "react";
import { Responses } from "types";

type AppContextState = {
  openSidenav: boolean;
  search: {
    data?: Responses["csSearchAccount"];
    isLoading: boolean;
    error?: string;
  };
};

enum AppContextActionType {
  OpenSidenav = "OPEN_SIDENAV",
  ChangeSearchState = "CHANGE_SEARCH_STATE",
}

type AppContextAction =
  | {
      type: AppContextActionType.OpenSidenav;
      value: boolean;
    }
  | {
      type: AppContextActionType.ChangeSearchState;
      value: AppContextState["search"];
    };

type IAppContext = [AppContextState, React.Dispatch<AppContextAction>];

export const AppContext = React.createContext<IAppContext>([
  {
    openSidenav: false,
    search: {
      isLoading: false,
      data: undefined,
      error: "",
    },
  },
  () => {},
]);

export function reducer(state: AppContextState, action: AppContextAction) {
  switch (action.type) {
    case "OPEN_SIDENAV": {
      return { ...state, openSidenav: action.value };
    }
    case "CHANGE_SEARCH_STATE": {
      return { ...state, search: action.value };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function AppContextControllerProvider({ children }) {
  const initialState: AppContextState = {
    openSidenav: false,
    search: {
      isLoading: false,
      data: undefined,
      error: "",
    },
  };

  const [controller, dispatch] = React.useReducer(reducer, initialState);
  const value = React.useMemo(
    () => [controller, dispatch],
    [controller, dispatch],
  );

  return (
    <AppContext.Provider value={value as any}>{children}</AppContext.Provider>
  );
}

export function useAppContextController() {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error(
      "useAppContextController should be used inside the AppContextControllerProvider.",
    );
  }

  return context;
}

export const setOpenSidenav = (
  dispatch: React.Dispatch<AppContextAction>,
  value: boolean,
) => dispatch({ type: AppContextActionType.OpenSidenav, value });
export const setSearchState = (
  dispatch: React.Dispatch<AppContextAction>,
  value: AppContextState["search"],
) => dispatch({ type: AppContextActionType.ChangeSearchState, value });
