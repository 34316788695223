import StatisticsChart from "components/Cards/StatisticsChart";

const getCountPercentage = (count: number, total: number) => {
  return ((count / total) * 100).toFixed(2);
};

export const PieChart = ({
  series,
  title,
  labels,
  colors,
  isLoading = false
}: {
  series: number[];
  title: string;
  colors: string[];
  labels: string[];
  isLoading?: boolean;
}) => {
  return (
    <StatisticsChart
      color="white"
      title={title}
      isLoading={isLoading}
      chart={{
        type: "donut",
        height: 220,
        series,
        options: {
          colors,
          labels,
          chart: {
            type: "donut"
          },
          tooltip: {
            theme: "dark",
            // @ts-ignore
            custom: function({ series, seriesIndex, _dataPointIndex, w }) {
              return (
                `<div class="arrow_box p-2 bg-[${w.globals.colors[seriesIndex]}]">` +
                "<span>" +
                w.globals.labels[seriesIndex] +
                ": " +
                series[seriesIndex] +
                " - " +
                getCountPercentage(
                  series[seriesIndex],
                  series.reduce((acc, curr) => acc + curr, 0)
                ) +
                "%" +
                "</span>" +
                "</div>"
              );
            }
          },
          dataLabels: {
            enabled: false
          }
        }
      }}
    />
  );
};
