import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Dialog, DialogBody, DialogHeader, Typography } from "@material-tailwind/react";
import { validateOTP } from "api/auth";
import FormInput from "components/Form/FormInput";
import FormWrapper from "components/Form/FormWrapper";
import { MFAModalForm, mfaModalFormSchema } from "components/MFA/MFAModal";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

type MFAConfirmDisableModalProps = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  setInputValue: (value: boolean) => void;
  updateMFAStatus: (isMFAEnabled: boolean) => Promise<void>;
};

const MFAConfirmDisableModal = ({ setIsOpen, isOpen, setInputValue, updateMFAStatus }: MFAConfirmDisableModalProps) => {
  const disableMFAFormMethods = useForm<MFAModalForm>({
    defaultValues: {
      otp: ""
    },
    resolver: zodResolver(mfaModalFormSchema)
  });

  const [isLoadingDisableMFA, setIsLoadingDisableMFA] = useState(false);
  const onSubmitDisableMFA = async (data: MFAModalForm) => {
    try {
      setIsLoadingDisableMFA(true);
      const response = await validateOTP(data.otp);
      if (response.otpValid) {
        await updateMFAStatus(false);
        setInputValue(false);
        setIsOpen(false);
        toast.success("2FA has been disabled successfully");
        return;
      }
      throw new Error("Failed to disable 2FA");
    } catch (error) {
      disableMFAFormMethods.setError("otp", {
        type: "manual",
        message: (error as any)?.message || "Failed to disable 2FA"
      });
    } finally {
      setIsLoadingDisableMFA(false);
    }
  };

  return (
    <Dialog
      size="xs"
      open={isOpen}
      handler={open => {
        setIsOpen(open);
        setInputValue(true);
      }}
    >
      <DialogHeader>
        <Typography variant="h4">Two-Factor Authentication (2FA) Disabling</Typography>
      </DialogHeader>
      <DialogBody className="text-blue-gray-800">
        <Typography>
          Are you sure you want to disable 2FA? You will be required to enable it again if you want to use it
        </Typography>
        <FormWrapper
          formMethods={disableMFAFormMethods}
          onSubmit={disableMFAFormMethods.handleSubmit(onSubmitDisableMFA)}
          className="mt-4"
        >
          <FormInput<MFAModalForm> containerClassName="w-56" name="otp" label="Enter OTP" type="text" />
          <div className="mt-4 flex gap-2 justify-end w-full">
            <Button
              variant="outlined"
              type="submit"
              color="red"
              loading={isLoadingDisableMFA}
              disabled={isLoadingDisableMFA}
              size="sm"
            >
              Disable
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
                setInputValue(true);
              }}
              disabled={isLoadingDisableMFA}
              size="sm"
            >
              Cancel
            </Button>
          </div>
        </FormWrapper>
      </DialogBody>
    </Dialog>
  );
};

export default MFAConfirmDisableModal;
