import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import App from "./App";
import "tailwind.css";
import { AppContextControllerProvider } from "context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  (
    <React.StrictMode>
      <BrowserRouter>
        <AppContextControllerProvider>
          <ThemeProvider
            children={
              (
                <>
                  <App />
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    newestOnTop
                    pauseOnHover
                    theme="light"
                    style={{
                      zIndex: 50
                    }}
                  />
                </>
              ) as any
            }
          />
        </AppContextControllerProvider>
      </BrowserRouter>
    </React.StrictMode>
  ) as any
);
